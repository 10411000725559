"use client";
import { cn } from "@/lib/utils";
import { useAppStore } from "@/store";

import { Button } from "@llamaindex/component/ui/button";

import { ResizablePanel } from "@llamaindex/component/ui/resizable";
import { motion } from "framer-motion";
import { ChevronRight } from "lucide-react";
import React, { PropsWithChildren, RefObject, useCallback } from "react";
import { ImperativePanelGroupHandle } from "react-resizable-panels";

export interface NavSkeletonProps extends PropsWithChildren {
  layoutRef: RefObject<ImperativePanelGroupHandle | null>;
}

export const NavSkeleton: React.FC<NavSkeletonProps> = ({
  layoutRef,
  children,
}) => {
  const panelSizes = useAppStore((state) => state.panelSizes);
  const isCollapsed = useAppStore((state) => state.isCollapsed);
  const setIsCollapsed = useAppStore((state) => state.setIsCollapsed);
  return (
    <ResizablePanel
      defaultSize={panelSizes[0]}
      collapsedSize={1}
      collapsible={true}
      minSize={8}
      maxSize={12}
      onCollapse={() => {
        setIsCollapsed(true);
      }}
      onExpand={() => {
        setIsCollapsed(false);
      }}
      className={cn(
        "relative flex h-full flex-col border-r border-r-slate-200 bg-gray-50 p-4",
        isCollapsed
          ? "min-w-[64px] max-w-[64px]"
          : "min-w-[200px] max-w-[200px]",
        "transition-all duration-300 ease-in-out",
      )}
      style={{
        overflow: "visible",
      }}
    >
      <Button
        asChild
        variant="outline"
        size="icon"
        className={cn(
          "absolute right-0 top-6 -mr-3 size-6 -translate-y-1/2 p-1",
        )}
        onClick={useCallback(() => {
          if (layoutRef.current) {
            const [l] = layoutRef.current.getLayout() as [number, number];
            layoutRef.current.setLayout(l <= 10 ? [33, 67] : [1, 99]);
          }
        }, [layoutRef])}
      >
        <motion.div layout="position">
          <ChevronRight
            className={cn(!isCollapsed && "rotate-180")}
            size={16}
          />
        </motion.div>
      </Button>
      {children}
    </ResizablePanel>
  );
};
